import { defaultState } from './state';
import types from './types';
import { DRAWER_SESSION_KEY } from '~/core/utils/constants';

export default {
  [types.SET_BOOK](state, book) {
    state.book = book;
  },

  [types.SET_HEADINGS](state, headings) {
    state.headings = headings;
  },

  [types.SET_FONT_SETTINGS](state, { fontSize, fontFamily }) {
    state.fontSettings.activeFamilyId = fontFamily || state.fontSettings.activeFamilyId;
    state.fontSettings.activeSizeId = fontSize || state.fontSettings.activeSizeId;
  },

  [types.SET_LANDING_DATA](state, data) {
    state.landingSettings = data;
  },

  [types.SET_NESTED_BOOK_DATA](state, data) {
    state.nestedData = data;
  },

  [types.SET_FLATTEN_SECTION](state, data) {
    state.flattenSections = data;
  },

  [types.SET_SECTION_SLUG](state, data) {
    state.sectionSlug = data;
  },

  [types.RESET_STATE](state) {
    Object.assign(state, defaultState());
  },

  [types.SET_DRAWER_SESSION](state, timestamp) {
    state.drawerSession = timestamp;
    sessionStorage.setItem(DRAWER_SESSION_KEY, timestamp);
  },

  [types.SET_COMPLETED_SECTIONS](state, data = []) {
    state.completedSections = data;
  },

  [types.ADD_COMPLETED_SECTION](state, section) {
    state.completedSections.push(section);
  },

  [types.REMOVE_COMPLETED_SECTION](state, sectionId) {
    state.completedSections = state.completedSections.filter((section) => section.id !== sectionId);
  },
};
