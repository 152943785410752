export default class LibraryService {
  constructor(context) {
    this.context = context;
  }

  extractHighlights(highlights) {
    const regex = /\S*<mark>[^<]*<\/mark>\S*/g;
    const extractedHighlights = [];

    highlights.forEach(({ snippet }) => {
      const match = snippet.match(regex);
      if (match) {
        extractedHighlights.push(...match);
      }
    });

    return extractedHighlights;
  }

  async getSectionHighlightsByTerm(term, section = {}) {
    const { title: sectionTitle } = section;

    if (!term || !sectionTitle) {
      return [];
    }

    const searchParams = {
      q: term,
      filter_by: `(section:= \`${sectionTitle}\`)`,
      query_by: 'content',
      highlight_affix_num_tokens: 100000000,
      snippet_threshold: 0,
      highlight_full_fields: 'content',
      per_page: 50,
      page: 1,
    };

    try {
      const { hits } = await this.context.$services.search.searchByTerm(term, searchParams);
      return hits.flatMap(({ highlights }) => highlights);
    } catch (error) {
      console.error('Error fetching section highlights:', error);
      throw error;
    }
  }
}
