import Typesense from 'typesense';

export default class SearchService {
  constructor({
    nuxtState: {
      config: { TYPESENSE: typesense },
    },
  }) {
    const {
      API_KEY: apiKey,
      HOST: host,
      PORT: port,
      PROTOCOL: protocol,
      TIMEOUT: connectionTimeoutSeconds,
    } = typesense;

    this.client = new Typesense.Client({
      apiKey,
      connectionTimeoutSeconds,
      nodes: [
        {
          host,
          protocol,
          port,
        },
      ],
    });

    this.abortController = new AbortController();
  }

  async searchByTerm(term, searchParams) {
    try {
      const response = await this.client.collections('textbook').documents().search(searchParams, {
        signal: this.abortController.signal,
      });
      return response;
    } catch (error) {
      if (error.name === 'AbortError') {
        console.warn('Search request was canceled.');
      } else {
        console.error('Error during search:', error);
      }
      throw error;
    }
  }

  cancelAll() {
    this.abortController.abort();
    this.abortController = new AbortController();
  }
}
