export default ($axios) => ({
  fetchSessions(params) {
    return $axios.get('/api/v2/caap/sessions', { params });
  },

  fetchStudentSessions(params) {
    return $axios.get('/api/caap/student/sessions', { params });
  },

  fetchSession(sessionId) {
    return $axios.get(`/api/caap/sessions/${sessionId}`);
  },

  cancelSession(sessionId) {
    return $axios.delete(`/api/caap/sessions/${sessionId}/enrollments`);
  },

  enrollSession(sessionId, headers) {
    return $axios.post(
      `/api/caap/sessions/${sessionId}/enrollments`,
      {},
      {
        headers,
      },
    );
  },

  joinSession(sessionId, logout = false) {
    return $axios.get(`/api/caap/sessions/${sessionId}/join?logout_other_devices=${logout}`);
  },

  playRecord(recordUrl, isEncore = 0, startFrom = 0) {
    return $axios.get(
      `/api/caap/classroom/generate-tmp-url/${recordUrl}?is_encore=${isEncore}&start_video_at=${startFrom}`,
    );
  },

  removeFromSessionWaitingList(sessionId) {
    return $axios.delete(`/api/caap/sessions/${sessionId}/remove-from-waiting`);
  },
});
