import { delay } from '~/core/utils/time';

const redirects = {
  '/services/live-online-mcat-tutoring': 'https://jackwestin.com/services/live-online-mcat-tutoring',
  '/services/medical-school-admissions-consulting':
    'https://jackwestin.com/services/medical-school-admissions-consulting',
};

export default async function ({ route }) {
  if (redirects[route.path]) {
    window.location.replace(redirects[route.path]);
    await delay(5000); // An extra delay to prevent visiting the old page during browser redirection
  }
}
