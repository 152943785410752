export const defaultState = () => ({
  fetchedProduct: {},
  subscriptionData: null,
  productPayments: [],
  userPaymentMethod: null,
  userCanSubscribe: true,
  productsId: {
    browserExtension: null,
  },
});

export default () => defaultState();
