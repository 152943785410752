import types from './types';

function normalizeSections(sections, parent, flattenSections) {
  return sections.map((section) => {
    const item = {
      ...section,
      type: 'section',
      id: `section-${section.id}`,
      entityId: section.id,
      parent,
      path: `${parent.path}/${section.slug}`,
      selectable: section.has_content ?? false,
      expandable: section.children.length > 0,
    };
    flattenSections.push(item);
    const node = {
      ...item,
      children: normalizeSections(section.children, item, flattenSections),
    };
    return node;
  });
}

function normalizeChapters(chapters, path) {
  const flattenSections = [];
  const result = chapters.map((chapter) => {
    const sections = chapter.sections ?? [];
    delete chapter.sections;
    const item = {
      ...chapter,
      type: 'chapter',
      path: `${path}/${chapter.slug}`,
      entityId: chapter.id,
      id: `chapter-${chapter.id}`,
    };
    return {
      ...item,
      children: normalizeSections(sections, item, flattenSections),
    };
  });
  return {
    result,
    flattenSections,
  };
}

export default {
  setHeadings({ commit }, payload) {
    commit(types.SET_HEADINGS, payload);
  },

  updateFontSetting({ commit }, { fontSize, fontFamily }) {
    commit(types.SET_FONT_SETTINGS, { fontFamily, fontSize });
  },

  async fetchBooks(_, params) {
    const { data } = await this.$api.library.fetchBooks(params);
    return data;
  },

  async fetchLastVisitedBook(_, params) {
    const { data } = await this.$api.library.fetchLastVisitedBook(params);
    return data;
  },

  async fetchLanding({ commit }) {
    const data = await this.$api.library.fetchLanding();
    commit(types.SET_LANDING_DATA, data);
    return data;
  },

  setSectionSlug({ commit }, data) {
    commit(types.SET_SECTION_SLUG, data);
  },

  async fetchBook({ commit }, { slug, path }) {
    const { data } = await this.$api.library.fetchBook(slug);
    const { result: normalizedData, flattenSections } = normalizeChapters(data.chapters, path);

    commit(types.SET_FLATTEN_SECTION, flattenSections);
    commit(types.SET_BOOK, data);
    commit(types.SET_NESTED_BOOK_DATA, normalizedData);

    return data;
  },

  navigateToFirstSectionOfChapter({ state }, chapterSlug) {
    const chapter = state.nestedData.find((chapter) => chapter.slug === chapterSlug);
    if (!chapter) {
      return;
    }

    const firstSectionOfChapter = state.flattenSections.find((section) => section.chapter_id === chapter.entityId);
    if (firstSectionOfChapter) {
      this.$router.replace(firstSectionOfChapter.path).catch(() => {});
    }
  },

  navigateToFirstChapter({ dispatch, state }) {
    const firstChapter = state.nestedData[0];
    if (firstChapter) {
      dispatch('navigateToFirstSectionOfChapter', firstChapter.slug);
    }
  },

  navigateToSection({ getters, dispatch }, { sectionId, replace = true }) {
    const section = getters.flattenSections.find((section) => section.entityId === sectionId);

    if (!section) {
      return dispatch('navigateToFirstChapter');
    }

    if (replace) {
      this.$router.replace(section.path).catch(() => {});
    } else {
      this.$router.push(section.path);
    }
  },

  async fetchSections(_, sectionSlug) {
    const { data } = await this.$api.library.fetchSectionContent(sectionSlug);
    return data;
  },

  resetState({ commit }) {
    commit(types.RESET_STATE);
  },

  setDrawerSession({ commit }, timestamp) {
    commit(types.SET_DRAWER_SESSION, timestamp);
  },

  async fetchBookStatus({ commit }, bookSlug) {
    const { data } = await this.$api.library.fetchBookStatus(bookSlug);
    commit('SET_COMPLETED_SECTIONS', data.completed_sections);

    return data;
  },

  async setSectionCompleteStatus({ commit }, params) {
    const { data } = await this.$api.library.setSectionCompleteStatus({
      sectionId: params.sectionId,
      complete: params.complete,
    });

    if (params.complete) {
      commit('ADD_COMPLETED_SECTION', {
        id: params.sectionId,
        completed_at: new Date().toISOString(),
      });
    } else {
      commit('REMOVE_COMPLETED_SECTION', params.sectionId);
    }

    return data;
  },
};
