export default {
  SET_BOOK: 'SET_BOOK',
  SET_HEADINGS: 'SET_HEADINGS',
  SET_FONT_SETTINGS: 'SET_FONT_SETTINGS',
  SET_LANDING_DATA: 'SET_LANDING_DATA',
  RESET_STATE: 'RESET_STATE',
  SET_LIBRARY_THEME: 'SET_LIBRARY_THEME',
  SET_CUSTOM_BLOCKS_ACTIVATED: 'SET_CUSTOM_BLOCKS_ACTIVATED',
  SET_NESTED_BOOK_DATA: 'SET_NESTED_BOOK_DATA',
  SET_FLATTEN_SECTION: 'SET_FLATTEN_SECTION',
  SET_SECTION_SLUG: 'SET_SECTION_SLUG',
  SET_DRAWER_SESSION: 'SET_DRAWER_SESSION',
  SET_COMPLETED_SECTIONS: 'SET_COMPLETED_SECTIONS',
  ADD_COMPLETED_SECTION: 'ADD_COMPLETED_SECTION',
  REMOVE_COMPLETED_SECTION: 'REMOVE_COMPLETED_SECTION',
};
