import { defaultState } from './state';
import types from './types';

export default {
  [types.RESET_STATE](state) {
    Object.assign(state, defaultState());
  },

  [types.SET_TERM](state, term) {
    state.term = term;
  },

  [types.SET_IS_HIGHLIGHT_MODE](state, isHighlightMode) {
    state.isHighlightMode = isHighlightMode;
  },
};
