export default {
  book: ({ book }) => ({
    ...book,
    path: `/books/${book.slug}`,
  }),
  breadcrumbs: (_, { activeSection, book }) => {
    if (!activeSection) {
      return [];
    }

    const sectionParent = activeSection.parent;
    const sectionParents = [activeSection, sectionParent];

    let parent = sectionParent.parent;
    while (parent) {
      sectionParents.push(parent);
      parent = parent.parent;
    }

    sectionParents.push(book);

    return sectionParents.reverse().map((item) => ({
      title: item.title,
      slug: item.slug,
      path: item.path ?? null,
    }));
  },
  activeFontFamily(_, { fontSettings }) {
    return fontSettings.families.find((font) => font.id === fontSettings.activeFamilyId);
  },
  activeFontSize(_, { fontSettings }) {
    return fontSettings.sizes.find((size) => size.id === fontSettings.activeSizeId);
  },
  breadcrumbsLabels: (_, { breadcrumbs }) => breadcrumbs.map(({ title }) => title),
  headings: ({ headings }) => headings,
  fontSettings: ({ fontSettings }) => fontSettings,
  nestedData: ({ nestedData }) => nestedData,
  landingSettings: ({ landingSettings }) => landingSettings,
  flattenSections: ({ flattenSections }) => flattenSections,
  sectionSlug: ({ sectionSlug }) => sectionSlug,
  currentSectionIndex(_, { sectionSlug, flattenSections }) {
    return flattenSections.findIndex((section) => section.slug === sectionSlug);
  },
  activeSection(_, { flattenSections, currentSectionIndex }) {
    if (currentSectionIndex === -1) {
      return null;
    }
    return flattenSections[currentSectionIndex];
  },
  nextSection(_, { flattenSections, currentSectionIndex }) {
    const remainingSections = flattenSections.slice(currentSectionIndex + 1);
    return remainingSections.find((section) => section.has_content === true) ?? null;
  },
  previousSection(_, { flattenSections, currentSectionIndex }) {
    const precedingSections = flattenSections.slice(0, currentSectionIndex);
    return precedingSections.reverse().find((section) => section.has_content === true) ?? null;
  },
  isDrawerSessionValid: ({ drawerSession }) => {
    const oneDay = 24 * 60 * 60 * 1000;
    return Date.now() - +drawerSession > oneDay;
  },
  completedSections: ({ completedSections }) => completedSections,
};
