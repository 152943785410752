import { defaultState } from './state';
import types from './types';

export default {
  [types.SET_PRODUCT](state, product) {
    state.fetchedProduct = product;
  },
  [types.SET_USER_PAYMENT_METHOD](state, userPaymentMethod) {
    state.userPaymentMethod = userPaymentMethod;
  },
  [types.RESET_STATE](state) {
    Object.assign(state, defaultState());
  },
  [types.SET_SUBSCRIPTION_DATA](state, subscriptions) {
    state.subscriptionData = subscriptions;
  },
  [types.SET_PRODUCT_PAYMENTS](state, payments) {
    state.productPayments = payments;
  },
  [types.SET_USER_CAN_SUBSCRIBE](state, canSubscribe) {
    state.userCanSubscribe = canSubscribe;
  },
  [types.SET_BROWSER_EXTENSION_UUID](state, browserExtensionUUID) {
    state.productsId.browserExtension = browserExtensionUUID;
  },
};
