
export default {
  props: {
    isRegisterMode: {
      type: Boolean,
      default: false,
    },
    hintMessage: {
      type: [String, null],
      default: null,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isSignIn: !this.isRegisterMode,
      showVerifyCode: false,
      email: null,
    };
  },

  computed: {
    clientId() {
      return this.$route.query.client_id || null;
    },
    socialText() {
      return this.isSignIn ? 'Sign in' : 'Sign up';
    },
  },

  methods: {
    showVerify(email) {
      this.showVerifyCode = !this.showVerifyCode;
      this.email = email;
    },
    toggleLoginForm(status) {
      if (status === 'signin') {
        this.isSignIn = true;
      } else if (status === 'signup') {
        this.isSignIn = false;
      }
    },
    signUp(method, url) {
      this.$gtmEvent.dispatch(this.isSignIn ? 'sign_in' : 'sign_up', {
        sign_up_method: method,
        successful: true,
      });

      const urlParams = new URLSearchParams(this.$route.query);

      if (!['login', 'register'].includes(this.$route.name)) {
        // The login modal is opened from a different page
        urlParams.append('redirect', this.$route.fullPath);
      }
      if (urlParams.toString()) {
        url += '?' + urlParams.toString();
      }
      window.location.href = url;
    },

    emitToParent(detail) {
      const message = { type: 'verify-auth', detail };
      const targetOrigin = this.$nuxt.context.$config.API_URL;
      window.parent.postMessage(message, targetOrigin);
    },
  },
};
