
export default {
  provide() {
    return {
      onVerifySuccessful: this.onVerifySuccessful,
      closeModal: this.closeModal,
      isIframeMode: false,
      isModalView: true,
      getAdditionalInfoDescription: this.getAdditionalInfoDescription,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    additionalInfoDescription: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      steps: ['ModalAdditionalInfo', 'ModalCodeVerification', 'ModalSuccessful'],
    };
  },
  created() {
    this.$nuxt.$on(this.$config.event.verified_mobile_and_test_date, () => {
      this.onVerifySuccessful();
    });
  },
  methods: {
    getAdditionalInfoDescription() {
      return this.additionalInfoDescription;
    },
    onVerifySuccessful() {
      this.$emit('success');
    },
    closeModal() {
      this.$emit('closed');
    },
  },
};
