import { render, staticRenderFns } from "./login-signup.vue?vue&type=template&id=e5b1d0fa"
import script from "./login-signup.vue?vue&type=script&lang=js"
export * from "./login-signup.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {JWInput: require('/app/components/ui/jw/JWInput.vue').default,PhoneNumberWithCountryCode: require('/app/components/profile/PhoneNumberWithCountryCode.vue').default,JWDateInput: require('/app/components/ui/jw/JWDateInput.vue').default,Btn: require('/app/components/ui/button/btn.vue').default})
