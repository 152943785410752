import { render, staticRenderFns } from "./BaseModals.vue?vue&type=template&id=ecb75ff4"
import script from "./BaseModals.vue?vue&type=script&lang=js"
export * from "./BaseModals.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoginModal: require('/app/components/login/login-modal.vue').default,VerifyPhoneNumberModal: require('/app/components/profile/otp/verifyModal/VerifyPhoneNumberModal.vue').default})
