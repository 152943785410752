import types from './types';

export default {
  resetSearchState({ commit }) {
    commit(types.RESET_STATE);
  },

  highlightTerm: ({ commit }, { term }) => {
    commit(types.SET_TERM, term);
    commit(types.SET_IS_HIGHLIGHT_MODE, true);
  },
};
