
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isRegisterMode: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show(isVisible) {
      if (isVisible) {
        document.documentElement.classList.add('no-scroll');
      } else {
        document.documentElement.classList.remove('no-scroll');
      }
    },
  },
  created() {
    this.$nuxt.$on(this.$config.event.logged_in, () => {
      this.$emit('success');
    });
  },
};
