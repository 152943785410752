const API_V1 = '/api/v1';
const BASE_API_PATH = `${API_V1}/stripe`;

export default ($axios) => ({
  fetchUserPaymentMethod() {
    return $axios.get(`${BASE_API_PATH}/users/default-payment-method`);
  },
  fetchProduct(id) {
    return $axios.get(`${BASE_API_PATH}/products/${id}/details`);
  },
  invoicePreview(params) {
    return $axios.post(`${BASE_API_PATH}/checkout/invoice-preview`, params);
  },
  createSubscription(params) {
    return $axios.post(`${BASE_API_PATH}/checkout/create-subscription`, params);
  },
  attachPaymentMethod(params) {
    return $axios.post(`${BASE_API_PATH}/checkout/attach-payment-method`, params);
  },
  fetchProductSubscriptionStatus(id) {
    return $axios.get(`${BASE_API_PATH}/products/${id}/subscription-status`);
  },
  fetchProductPayments(id) {
    return $axios.get(`${BASE_API_PATH}/products/${id}/payments`);
  },
  cancelProductSubscription(id) {
    return $axios.delete(`${BASE_API_PATH}/subscriptions/${id}/cancel`);
  },
  fetchBrowserExtensionUUID() {
    return $axios.get(`${API_V1}/browser-extension/active-product`);
  },
});
