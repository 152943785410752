export default (Vue) => {
  function getKeyCode(expression) {
    switch (expression) {
      case 'up':
        return 38;
      case 'down':
        return 40;
      case 'space':
        return 32;
      default:
        return expression?.toUpperCase()?.charCodeAt(0);
    }
  }

  Vue.directive('shortkey', {
    inserted(el, binding, vnode) {
      const eventHandler = function (e) {
        if (el.disabled) {
          return;
        }

        if (binding.modifiers.alt && !e.altKey) {
          return;
        }
        if (binding.modifiers.ctrl && !e.ctrlKey && !e.metaKey) {
          return;
        }

        const keyCodes = Array.isArray(binding.value) ? binding.value.map(getKeyCode) : [getKeyCode(binding.value)];

        if (!keyCodes.includes(e.keyCode)) {
          return;
        }

        vnode.data.on.shortkey(el);
      };

      el._vueShortKeyEventHandler = eventHandler;
      document.addEventListener('keydown', eventHandler);
    },
    unbind(el) {
      if (el._vueShortKeyEventHandler) {
        document.removeEventListener('keydown', el._vueShortKeyEventHandler);
      }
    },
  });
};
