export const DECK_IMAGE_RATIO = 2.1;

export const BREAK_POINTS = {
  XL: 1280,
  LG: 1024,
  MD: 768,
  LARGE: 1280,
  WIDE: 1920,
};

export const RECENT_SEARCH_LOCAL_STORAGE_KEY = 'platform/setRecentSearches';

export const CARD_TYPES = {
  BASIC: {
    value: '0',
    name: 'Basic',
  },
  CLOZE: {
    value: '1',
    name: 'Cloze',
  },
};

export const DISCOUNT_TYPES = {
  FIXED: 'FIXED',
  PERCENTAGE: 'PERCENTAGE',
};

export const QUIZ_FEATURES = {
  // GENERAL
  TIMER: 'Timer',
  NEXT: 'Next',
  PREV: 'Prev',
  PAGINATION: 'Pagination',
  REVIEW_ALL: 'ReviewAll',
  REVIEW_MARKED: 'ReviewMarked',
  REVIEW_INCOMPLETE: 'ReviewIncomplete',
  // MCAT
  PAUSE: 'Pause',
  END_EXAM: 'EndExam',
  FLAG: 'Flag',
  NAVIGATION: 'Navigation',
  HIGHLIGHT: 'Highlight',
  STRIKE: 'Strike',
  PERIODIC_TABLE: 'PeriodicTable',
  END_SECTION: 'EndSection',
  REVIEW_FLAGGED: 'ReviewFlagged',
  REVIEW_SCREEN: 'ReviewScreen',
  // DAT
  END: 'End',
  YES: 'Yes',
  NO: 'No',
  MARK: 'Mark',
  EXHIBIT: 'Exhibit',
  REVIEW: 'Review',
  //SAT
  ANNOTATE: 'Annotate',
  CALCULATOR: 'Calculator',
  REFERENCES: 'References',
};

export const TRUSTED_SITES_LOCAL_STORAGE_KEY = 'trustedSites';

export const USER_COOKIE_CHOICES = {
  NOT_CHOICE: null,
  REJECTED: 0,
  ACCEPTED: 1,
};

export const ACCESS_COOKIE_VALUES = {
  SHOULD_NOT_DISPLAY: 0,
  NOT_FROM_USA: 1,
  FROM_USA: 2,
};

export const DEFAULT_COOKIE_VALUES = {
  SHOULD_NOT_TRACK: 0,
  SHOULD_TRACK: 1,
};

export const SURVEY_DISMISSED_TEST_IDS = 'SURVEY_DISMISSED_TEST_IDS';
export const DRAWER_SESSION_KEY = 'drawerSession';
