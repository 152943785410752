import types from './types';

export default {
  async fetchStudentCourses({ commit }) {
    const { data } = await this.$api.student.fetchStudentCourses();
    commit(types.SET_STUDENT_COURSES, data);
  },

  async fetchStudentTiers({ commit }) {
    const { data } = await this.$api.student.fetchStudentTiers();
    commit(types.SET_STUDENT_TIERS, data);
  },

  async fetchStudentTier({ commit }, tierId) {
    const { data } = await this.$api.student.fetchStudentTier(tierId);
    // set tier
    commit(types.SET_STUDENT_TIER, data);
    // set purchases
    commit(types.SET_PURCHASES, data?.enrollment?.purchases ?? []);
    // set courses
    commit(types.SET_TIER_CLASS_ENROLLMENTS, data?.enrollment?.tier_class_enrollments);
    return data;
  },

  async fetchStudentCourse({ commit }, courseId) {
    const { data } = await this.$api.student.fetchStudentCourse(courseId);
    commit(types.SET_PURCHASES, data?.enrollment?.purchases ?? []);
    commit(types.SET_STUDENT_COURSE, data);
    return data;
  },

  async fetchIsUserWaitingForNextSession({ commit }, courseId) {
    const { data } = await this.$api.student.fetchIsUserWaitingForNextSession(courseId);
    commit(types.SET_USER_WAITING_FOR_NEXT_SESSION, data.is_waiting);
  },

  async fetchStudentEnrollment({ commit }, courseId) {
    const { data } = await this.$api.student.fetchStudentEnrollment(courseId);
    commit(types.SET_STUDENT_ENROLLMENTS, data);
  },

  async fetchStudentEnrollmentClass({ commit }, classId) {
    const { data } = await this.$api.student.fetchStudentEnrollmentClass(classId);
    commit(types.SET_STUDENT_ENROLLMENTS_CLASS, data);
  },

  async fetchRechargePayment(_, purchaseId) {
    const res = await this.$api.student.fetchRechargePayment(purchaseId);
    return res;
  },

  async fetchUpcomingInvoice({ commit }, enrollmentId) {
    const { data } = await this.$api.student.fetchUpcomingInvoice(enrollmentId);
    commit(types.SET_UPCOMING_INVOICE, data);
  },

  async fetchStudentCards({ commit }) {
    const { data } = await this.$api.student.fetchStudentCards();
    commit(types.SET_STUDENT_CARDS, data);
  },

  /**
   * name: string, email: string
   * @param {*} params
   */
  async updateProfile(_, params) {
    const { data } = await this.$api.student.updateProfile(params);
    this.$services.user.updateUserInfo(data, true);
  },

  async updateDefaultCard({ dispatch }, paymentMethodId) {
    await this.$api.student.updateDefaultCard(paymentMethodId);
    await dispatch('fetchStudentCards');
  },

  async setupIntent({ dispatch }) {
    const { data } = await this.$api.student.setupIntent();
    await dispatch('fetchStudentCards');
    return data;
  },

  async deleteCard({ dispatch }, paymentMethodId) {
    await this.$api.student.deleteCard(paymentMethodId);
    await dispatch('fetchStudentCards');
  },

  async addCard({ dispatch }, paymentMethodId) {
    await this.$api.student.addCard(paymentMethodId);
    await dispatch('fetchStudentCards');
  },

  async disconnectFromSocial(_, provider) {
    const { data } = await this.$api.student.disconnectFromSocial(provider);
    this.$services.user.updateUserInfo(data, true);
  },

  async getRemainingBalanceTax(_, enrollment) {
    const { data } = await this.$api.student.getRemainingBalanceTax(enrollment);
    return data;
  },

  async payRemainingBalance(_, enrollment) {
    await this.$api.student.payRemainingBalance(enrollment);
  },

  async switchSendyNotifications(_, settings) {
    const { data } = await this.$api.student.switchSendyNotifications(settings);
    this.$services.user.updateUserInfo(data, true);
  },

  async switchCommentsNotifications(_, toggle) {
    const { data } = await this.$api.student.switchCommentsNotifications(toggle);
    this.$services.user.updateUserInfo(data, true);
  },

  async fetchStudentExtension({ commit }, enrollmentId) {
    const { data } = await this.$api.student.fetchStudentExtension(enrollmentId);
    commit(types.SET_EXTENSIONS, data);
  },

  addUserToWaittingList({ commit }, isWaiting) {
    commit(types.SET_USER_WAITING_FOR_NEXT_SESSION, isWaiting);
  },

  async fetchPaymentDetail({ commit }, enrollmentId) {
    const { data } = await this.$api.student.paymentDetail(enrollmentId);
    commit(types.SET_PAYMENT_DETAIL, data);
  },

  async connectedSocials({ commit }) {
    const { data } = await this.$api.student.connectedSocials();
    if (data) {
      commit(types.SET_USER_INFO, data);
    }
  },

  async fetchHasStudentExtendedCourse({ commit }, courseId) {
    const { data } = await this.$api.student.fetchHasStudentExtendedCourse(courseId);
    commit(types.SET_HAS_STUDENT_EXTENDED_COURSE, data?.is_user_extended);
  },

  /**
   * request to the server for send OTP code to user for validate new email.
   * @author HN
   * @param {String} email
   * @returns {Promise<{ request_id: {String} }>}
   */
  async sendOTPForEmailUpdating(_, email) {
    const { data } = await this.$api.student.sendOTPForEmailUpdating(email);
    return data;
  },

  /**
   * request to the server for updating an email.
   * @author HN
   * @param {Number} otp
   * @param {String} request_id
   * @returns {Promise<UserModel>}
   */
  async updateEmail(_, { otp, request_id }) {
    const { data } = await this.$api.student.updateEmail(otp, request_id);
    this.$services.user.updateUserInfo(data, true);
    return data;
  },

  changeThankyouPageStatus({ commit }, status) {
    commit(types.CHANGE_THANKYOU_PAGE_STATUS, status);
  },

  setDiscountCode({ commit }, value) {
    commit(types.ENABLED_DISCOUNT_CODE, value);
  },
};
