export default ($axios) => ({
  fetchTests(params) {
    return $axios.get('/api/daily/qbank/test', { params });
  },

  fetchTestBySlug(slug) {
    return $axios.get(`/api/daily/qbank/test/${slug}`);
  },

  fetchApps() {
    return $axios.get('/api/daily/apps');
  },

  fetchFoundations({ sectionId, params }) {
    return $axios.get('/api/daily/tags', {
      params: {
        type: 'foundation',
        section: sectionId,
        pool: params.pool ?? undefined,
      },
    });
  },

  fetchSections({ appId, params }) {
    return $axios.get('/api/daily/sections', {
      params: {
        app: appId,
        pool: params.pool ?? undefined,
      },
    });
  },

  fetchTestAnalysis(testId) {
    return $axios.get(`/api/daily/qbank/test/${testId}/analysis`);
  },

  fetchTestAnalysis2(testId) {
    // https://testing.jackwestin.com/documents/daily-v2-tests/it-can-get-test-analysis.html
    return $axios.get(`/api/v2/daily/qbank/test/${testId}/analysis`);
  },

  fetchTestResult(testId) {
    return $axios.get(`/api/daily/qbank/test/${testId}/result`);
  },

  createTest(params) {
    return $axios.post('/api/daily/qbank/test', params);
  },

  updateTest({ testId, params }) {
    return $axios.patch(`/api/daily/qbank/test/${testId}`, params);
  },

  createCustomTest(testId) {
    return $axios.post(`/api/daily/qbank/tests/${testId}/duplicate`);
  },

  fetchTestUsage() {
    return $axios.get('/api/daily/qbank/usage');
  },

  fetchTestStatistics() {
    return $axios.get('/api/daily/qbank/statistics');
  },

  fetchTestDetail(testId) {
    return $axios.get(`/api/daily/qbank/test/${testId}`);
  },

  fetchBreakdownBuySubject(test = undefined) {
    return $axios.get('/api/daily/qbank/subjects', {
      params: {
        test,
      },
    });
  },

  fetchBreakdownBuyDifficulty(test = undefined) {
    return $axios.get('/api/daily/qbank/tests/difficulty', {
      params: {
        test,
      },
    });
  },

  fetchUserSubjects() {
    return $axios.get('/api/daily/qbank/user_subjects');
  },

  fetchChartUserAccuracy(params = '') {
    return $axios.get('/api/daily/qbank/daily_accuracy/', { params });
  },

  deleteSingleTest(testId) {
    return $axios.delete(`/api/daily/qbank/test/${testId}`);
  },

  deleteUserTests() {
    return $axios.delete('/api/daily/qbank/tests');
  },

  deleteSubjectTests(subjectId) {
    return $axios.delete(`/api/daily/qbank/tests?subject_id=${subjectId}`);
  },
});
