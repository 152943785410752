const API_V1 = '/api/v1';
const API_V1_BASE = `${API_V1}/library`;
const API_V1_PATH = `${API_V1_BASE}/books`;

export default ($axios) => ({
  fetchBooks(params) {
    return $axios.get(API_V1_PATH, { params });
  },

  fetchLastVisitedBook(params) {
    return $axios.get(`${API_V1_PATH}/last_visited`, { params });
  },

  fetchChapters(params) {
    return $axios.get(`${API_V1_PATH}/chapters?`, {
      params,
    });
  },

  fetchSections(params) {
    return $axios.get(`${API_V1_PATH}/chapters/sections?`, {
      params,
    });
  },

  fetchLanding() {
    return $axios.get(`${API_V1_BASE}/landing`);
  },

  fetchBook(slug) {
    return $axios.get(`${API_V1_PATH}/${slug}`);
  },

  fetchSectionContent(slug) {
    return $axios.get(`${API_V1_PATH}/sections/${slug}/content`);
  },

  fetchBookStatus(slug) {
    return $axios.get(`${API_V1_PATH}/${slug}/status`);
  },

  setSectionCompleteStatus(params) {
    return $axios.post(`${API_V1_PATH}/sections/${params.sectionId}/complete`, {
      complete: params.complete,
    });
  },
});
