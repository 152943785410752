import types from './types';

export default {
  async resetState({ commit }) {
    commit(types.RESET_STATE);
  },
  async fetchUserPaymentMethod({ commit }) {
    const { data } = await this.$api.products.fetchUserPaymentMethod();
    commit(types.SET_USER_PAYMENT_METHOD, data);
    return data;
  },
  async fetchProduct({ commit }, id) {
    const { data } = await this.$api.products.fetchProduct(id);
    commit(types.SET_USER_CAN_SUBSCRIBE, data.can_subscribe);
    commit(types.SET_PRODUCT, data);
    return data;
  },
  async invoicePreview(_, params) {
    return await this.$api.products.invoicePreview(params);
  },
  async createSubscription(_, params) {
    return await this.$api.products.createSubscription(params);
  },
  async attachPaymentMethod(_, params) {
    return await this.$api.products.attachPaymentMethod(params);
  },
  async fetchProductSubscriptionStatus({ commit }, id) {
    const { data } = await this.$api.products.fetchProductSubscriptionStatus(id);
    commit(types.SET_SUBSCRIPTION_DATA, data);
    return data;
  },
  async fetchProductPayments({ commit }, id) {
    const { data } = await this.$api.products.fetchProductPayments(id);
    commit(types.SET_PRODUCT_PAYMENTS, data);
    return data;
  },
  async cancelProductSubscription(_, id) {
    const { data } = await this.$api.products.cancelProductSubscription(id);
    return data;
  },
  async fetchBrowserExtensionUUID({ commit }) {
    const { data } = await this.$api.products.fetchBrowserExtensionUUID();
    commit(types.SET_BROWSER_EXTENSION_UUID, data.uuid);
    return data;
  },
};
