const BASE_API_PATH = '/api/v1/caap/payments';

export default class PaymentAPI {
  constructor($axios) {
    this.$axios = $axios;
  }

  fetchUserBillingAddresses() {
    return this.$axios.get(`${BASE_API_PATH}/billing-addresses`);
  }

  calculateTaxForSavedAddress(params) {
    return this.$axios.post(`${BASE_API_PATH}/tax/calculate`, params);
  }

  deleteBillingAddress(id) {
    return this.$axios.delete(`${BASE_API_PATH}/billing-addresses/${id}`);
  }
}
