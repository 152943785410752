export default class PaymentService {
  constructor(context) {
    this.context = context;
  }

  confirmAffirmPayment(clientSecret, returnUrl, stripe) {
    return stripe
      .confirmAffirmPayment(clientSecret, {
        payment_method: {
          billing_details: {
            email: this.context.$auth.user.email,
            name: this.context.$auth.user.name,
          },
        },
        return_url: returnUrl,
      })
      .then((result) => {
        if (result.error) {
          this.context?.$bugsnag?.notify(`An error occurred on the confirming affirm purchase result: ${result.error}`);
          this.context?.$jwToast?.error('Something went wrong. Please reload and try again.');
        }
      })
      .catch((err) => {
        this.context?.$bugsnag?.notify(`An error occurred on the confirming affirm purchase: ${err}`);
        this.context?.$log(err);
      });
  }

  async fetchUserBillingAddresses() {
    try {
      const { data } = await this.context.$api.payment.fetchUserBillingAddresses();
      return data.map((address) => {
        return { name: address.name, address };
      });
    } catch (error) {
      this.context?.$bugsnag?.notify(error);
      this.context?.$log(error);
      throw error;
    }
  }

  deleteBillingAddress(id) {
    return this.context.$api.payment.deleteBillingAddress(id);
  }

  async calculateTaxForSavedAddress(params) {
    try {
      const { data } = await this.context.$api.payment.calculateTaxForSavedAddress(params);
      return data;
    } catch (error) {
      this.context?.$bugsnag?.notify(error);
      this.context?.$log(error);
      throw error;
    }
  }
}
