import debounce from 'lodash/debounce';

let highlightFound = 0;
let firstHighlightId = null;

function removeMarkTag(str) {
  return str.replace(/<\/?mark>/g, '');
}

function escapeSpecialChars(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getTextNodesUnder(node) {
  const textNodes = [];
  const walker = document.createTreeWalker(node, NodeFilter.SHOW_TEXT, {
    acceptNode: function (node) {
      // Skip script and style contents
      if (
        node.parentNode.nodeName === 'SCRIPT' ||
        node.parentNode.nodeName === 'STYLE' ||
        node.parentNode.classList.contains('search-hl')
      ) {
        return NodeFilter.FILTER_REJECT;
      }
      return NodeFilter.FILTER_ACCEPT;
    },
  });

  let currentNode;
  while ((currentNode = walker.nextNode())) {
    textNodes.push(currentNode);
  }
  return textNodes;
}

function findMatches(text, searchTerms) {
  const matches = [];

  searchTerms.forEach((term) => {
    const extractWithoutMark = removeMarkTag(term);
    const escapedExtract = escapeSpecialChars(extractWithoutMark);
    const regex = new RegExp(`(${escapedExtract})`, 'g');

    let match;
    while ((match = regex.exec(text)) !== null) {
      matches.push({
        text: term,
        index: match.index,
        length: extractWithoutMark.length,
      });
    }
  });

  matches.sort((a, b) => a.index - b.index);

  return matches.filter((match, index) => {
    if (index === 0) {
      return true;
    }
    const prevMatch = matches[index - 1];
    return match.index >= prevMatch.index + prevMatch.length;
  });
}

function initializeHighlighting(el, binding, vnode) {
  if (el._observer) {
    el._observer.disconnect();
  }

  // Create a debounced highlight function
  const debouncedHighlight = debounce(() => {
    if (binding.value && Array.isArray(binding.value) && binding.value.length > 0) {
      highlightTextContent(el, binding.value, vnode);
    }
    // After highlighting, check if we should continue observing
    if (el._observer && document.contains(el)) {
      // If there are no highlights found, continue observing for changes
      const highlights = el.querySelectorAll('.search-hl');
      if (highlights.length === 0) {
        el._observer.observe(el, {
          childList: true,
          subtree: true,
          characterData: true,
        });
      }
    }
  }, 100); // Adjust debounce time as needed

  // Create mutation observer
  el._observer = new MutationObserver(() => {
    // Stop observing while we process changes to prevent infinite loops
    el._observer.disconnect();
    debouncedHighlight();
  });

  // Initial observation
  el._observer.observe(el, {
    childList: true,
    subtree: true,
    characterData: true,
  });

  // Initial highlight attempt
  debouncedHighlight();
}

function highlightTextContent(el, searchTerms, vnode) {
  highlightFound = 0;
  // Remove existing highlights first
  const existingHighlights = el.querySelectorAll('.search-hl');
  existingHighlights.forEach((highlight) => {
    const textNode = document.createTextNode(highlight.textContent);
    highlight.parentNode.replaceChild(textNode, highlight);
  });

  // Get all text nodes
  const textNodes = getTextNodesUnder(el);

  textNodes.forEach((textNode) => {
    const content = textNode.textContent;
    const matches = findMatches(content, searchTerms);

    if (matches.length > 0) {
      let lastIndex = 0;
      let fragments = [];

      matches.forEach((match) => {
        if (match.index > lastIndex) {
          fragments.push(document.createTextNode(content.slice(lastIndex, match.index)));
        }

        highlightFound++;

        const span = document.createElement('span');
        span.className = 'search-hl';
        span.id = `search-hl-${highlightFound}`;
        span.innerHTML = match.text;

        // it's a hack to fix the issue with the mark tag (temporary solution)
        if (match.text.includes('(') && !match.text.includes(')')) {
          span.innerHTML = span.innerHTML + ' ';
        }

        fragments.push(span);

        lastIndex = match.index + match.length;
      });

      if (lastIndex < content.length) {
        fragments.push(document.createTextNode(content.slice(lastIndex)));
      }

      const fragment = document.createDocumentFragment();
      fragments.forEach((f) => fragment.appendChild(f));
      textNode.parentNode.replaceChild(fragment, textNode);
    }
  });

  const firstHighlight = el.querySelector('.search-hl');
  if (firstHighlight && firstHighlightId !== firstHighlight.id) {
    firstHighlightId = firstHighlight.id;
    // firstHighlight.scrollIntoView({ behavior: 'smooth', block: 'center' });
    vnode.data.on?.['highlights-applied'](firstHighlight);
  }
}

export default (Vue) => {
  Vue.directive('search-highlight', {
    bind(el, binding, vnode) {
      // Initialize when the directive is first bound
      vnode.context.$nextTick(() => {
        initializeHighlighting(el, binding, vnode);
      });

      // Also initialize on mounted event of the component
      if (vnode.componentInstance) {
        vnode.componentInstance.$on('hook:mounted', () => {
          initializeHighlighting(el, binding, vnode);
        });
      }
    },

    update(el, binding, vnode) {
      if (binding.oldValue === binding.value) {
        return;
      }

      firstHighlightId = null;

      vnode.context.$nextTick(() => {
        if (!binding.value || !Array.isArray(binding.value) || binding.value?.length === 0) {
          if (el._observer) {
            el._observer.disconnect();
            delete el._observer;
          }
          const highlights = el.querySelectorAll('.search-hl');
          highlights.forEach((highlight) => {
            const textNode = document.createTextNode(highlight.textContent);
            highlight.parentNode.replaceChild(textNode, highlight);
          });
          return;
        }

        initializeHighlighting(el, binding, vnode);
      });
    },

    unbind(el) {
      firstHighlightId = null;
      // Clean up observer
      if (el._observer) {
        el._observer.disconnect();
        delete el._observer;
      }

      // Remove highlights
      const highlights = el.querySelectorAll('.search-hl');
      highlights.forEach((highlight) => {
        const textNode = document.createTextNode(highlight.textContent);
        highlight.parentNode.replaceChild(textNode, highlight);
      });
    },
  });
};
